import './App.css'
import LandingPage from './components/landingpage'




function App() {
  return (
    <div>
      <LandingPage />
    </div>
  )
}

export default App
